<template>
  <v-row>
    <v-col cols="8">
      <v-card>
        <v-item-group v-model="currentStep" class="text-center justify-content-center" mandatory>
          <v-item v-slot="{ active }">
            <v-btn dense text tile :style="{
              opacity: active ? 1 : 0.8,
              'border-bottom': currentStep >= 0 ? '5px solid green' : 'none',
              width: 'auto',
              padding: '0 0.5rem',
            }" @click="setCurrentStepAndStatus(0)">
              1 - Projeto
              <v-icon v-if="(active || currentStep >= 0)" class="ml-3" small color="green">
                mdi-lock-open-check-outline
              </v-icon>
            </v-btn>
          </v-item>
          <v-item v-slot="{ active }">
            <v-btn dense text tile :style="{
              opacity: active ? 1 : 0.8,
              'border-bottom': currentStep >= 1 ? '5px solid green' : 'none',
              width: 'auto',
              padding: '0 0.5rem',
            }"
              @click="setCurrentStepAndStatus(1)">
              2 - Pareamento de rubricas
              <v-icon
                v-if="projeto.status >= StatusProjetoEnum.EM_DEFINICAO_PROJETO"
                class="ml-3" small color="green">
                mdi-lock-open-check-outline
              </v-icon>
              <v-icon
                v-if="projeto.status == StatusProjetoEnum.ATIVO"
                class="ml-3" small color="green">
                mdi-lock-check
              </v-icon>
            </v-btn>
          </v-item>
          <v-item v-slot="{ active }">
            <v-btn dense text tile :style="{
              opacity: active ? 1 : 0.8,
              'border-bottom': currentStep >= 2 ? '5px solid green' : 'none',
              width: 'auto',
              padding: '0 0.5rem',
            }"
              @click="setCurrentStepAndStatus(2)">
              3 - Parametrização
              <v-icon
                v-if="projeto.status >= StatusProjetoEnum.EM_DEFINICAO_PROJETO"
                class="ml-3" small color="green">
                mdi-lock-open-check-outline
              </v-icon>
              <v-icon
                v-if="projeto.status == StatusProjetoEnum.ATIVO"
                class="ml-3" small color="green">
                mdi-lock-check
              </v-icon>
            </v-btn>
          </v-item>
          <v-item v-slot="{ active }">
            <v-btn dense text tile :style="{
              opacity: active ? 1 : 0.8,
              'border-bottom': currentStep >= 3 ? '5px solid green' : 'none',
              width: 'auto',
              padding: '0 0.5rem',
            }"
              @click="setCurrentStepAndStatus(3)">
              4 - Orçamento
              <v-icon
                v-if="projeto.status >= StatusProjetoEnum.EM_DEFINICAO_PROJETO"
                class="ml-3" small color="green">
                mdi-lock-open-check-outline
              </v-icon>
              <v-icon
                v-if="projeto.status == StatusProjetoEnum.ATIVO"
                class="ml-3" small color="green">
                mdi-lock-check
              </v-icon>
            </v-btn>
          </v-item>
        </v-item-group>
        <v-window v-model="currentStep">
          <v-window-item>
            <v-row>
              <v-col class="ml-3">
                <v-card-title>
                  {{ !isEdicaoProjeto ? 'Ficha de inclusão de projeto de captação' : 'Edição projeto captação' }}
                </v-card-title>
                <v-card-text>
                  <v-row class="ml-n4 mr-0">
                    <input-v v-for="(field, index) of cols" class="pl-4" :colSize="field.colSize" :key="index"
                      :label="field" :opts="opts" :valid.sync="field.valid" v-model="projeto[field.key]"
                      @changed="changedField(field.key, $event)" @input="changedField(field.key, $event)"></input-v>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-window-item>
          <!-- <v-window-item :eager="isEdicaoProjeto && (projeto.status >= StatusProjetoEnum.EM_PAREAMENTO || projeto.status < StatusProjetoEnum.EM_DEFINICAO_PROJETO)"> -->
          <v-window-item :eager="isEdicaoProjeto">
            <DescricaoDispendios :disabled="isAtivo || isFinalizado || isCancelado"></DescricaoDispendios>
          </v-window-item>
          <!-- <v-window-item :eager="isEdicaoProjeto && (projeto.status >= StatusProjetoEnum.EM_PARAMETRIZACAO  || projeto.status < StatusProjetoEnum.EM_DEFINICAO_PROJETO)"> -->
          <v-window-item>
            <ParametrizacaoOrcamento :disabled="isAtivo || isFinalizado || isCancelado" @parcelas="validaParcelas($event)"></ParametrizacaoOrcamento>
          </v-window-item>
          <!-- <v-window-item :eager="isEdicaoProjeto && (projeto.status >= StatusProjetoEnum.EM_DETALHAMENTO  || projeto.status < StatusProjetoEnum.EM_DEFINICAO_PROJETO)"></v-window-item> -->
          <v-window-item>
            <OrcamentoDetalhado :disabled="isAtivo || isFinalizado || isCancelado"></OrcamentoDetalhado>
          </v-window-item>
        </v-window>
      </v-card>
    </v-col>
    <v-col class="mr-3">
      <v-card>
        <v-card-actions class="d-flex justify-content-between">
          <v-btn color="secondary" depressed @click="currentStep--" :disabled="currentStep == 0">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn color="primary" depressed @click="doSave(false)" v-if="currentStep != 3 &&
            (projeto.status != StatusProjetoEnum.CANCELADO && projeto.status != StatusProjetoEnum.FINALIZADO)">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn color="primary" depressed
            @click="isCancelado || isFinalizado ? doFinalizeProject() : isVisibleModalProjectWarning = true"
            :disabled="projeto.status == StatusProjetoEnum.ATIVO"
            v-if="currentStep == 3 || (projeto.status == StatusProjetoEnum.CANCELADO || projeto.status == StatusProjetoEnum.FINALIZADO)">
            {{ isCancelado || isFinalizado ? 'Encerrar' : 'Concluir' }}
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="mt-5 p-3">
        <v-card-title>FINEP - Responsáveis</v-card-title>
        <v-card-text class="mt-n4 mb-n12">
          <v-row class="ml-n4 mr-0">
            <v-col cols="12 mb-n4">
              <p>Responsável financeiro</p>
              <div class="d-flex flex-column mt-3">
                <v-select
                  v-model="projeto.financeiroFinep"
                  :items="opts.responsaveisFinep"
                  item-text="nome"
                  item-value="id"
                  outlined
                  :disabled="isCancelado || isFinalizado || isAtivo"
                  @change="responsavelFinanceiroChanged($event)">
                  <template v-slot:label>
                    <p style="font-size: 13px">Responsável financeiro</p>
                  </template>
                  <template v-slot:item="{ item }">
                    <p style="font-size: 13px">{{ item.nome }}</p>
                  </template>
                  <template v-slot:selection="{ item }">
                    <p style="font-size: 13px; margin-bottom: 0px" class="text-truncate">{{ item.nome }}</p>
                  </template>
                </v-select>
              </div>
              <div class="d-flex flex-row mt-n4">
                <div class="d-flex flex-column">
                  <v-input :messages="projeto.financeiroFinep ? projeto.financeiroFinep.telefone : 'Telefone não cadastrado'">
                    <b>Telefone</b>
                  </v-input>
                  <v-input :messages="projeto.financeiroFinep ? projeto.financeiroFinep.email : 'E-mail não cadastrado'" class="my-1">
                    <b>E-mail</b>
                  </v-input>
                </div>
              </div>
            </v-col>
            <v-col cols="12" class="mt-5">
              <p>Responsável técnico</p>
              <div class="d-flex flex-column mt-3">
                <v-select
                  v-model="projeto.tecnicoFinep"
                  :items="opts.responsaveisFinep"
                  item-text="nome"
                  item-value="id"
                  outlined
                  :disabled="isCancelado || isFinalizado || isAtivo"
                  @change="responsavelTecnicoChanged($event)">
                  <template v-slot:label>
                    <p style="font-size: 13px">Responsável técnico</p>
                  </template>
                  <template v-slot:item="{ item }">
                    <p style="font-size: 13px">{{ item.nome }}</p>
                  </template>
                  <template v-slot:selection="{ item }">
                    <p style="font-size: 13px; margin-bottom: 0px" class="text-truncate">{{ item.nome }}</p>
                  </template>
                </v-select>
              </div>
              <div class="d-flex flex-row mt-n4">
                <div class="d-flex flex-column">
                  <v-input :messages="projeto.tecnicoFinep ? projeto.tecnicoFinep.telefone : 'Telefone não cadastrado'">
                    <b>Telefone</b>
                  </v-input>
                  <v-input :messages="projeto.tecnicoFinep ? projeto.tecnicoFinep.email : 'E-mail não cadastrado'" class="my-1">
                    <b>E-mail</b>
                  </v-input>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end mt-5">
          <v-btn
            depressed
            color="primary"
            dark
            :disabled="(this.isCancelado || this.isFinalizado) || this.isAtivo"
            @click="isVisibleAddFinep = true"
          >
            + Cadastrar responsável
          </v-btn>
        </v-card-actions>
        <!-- <v-card-text class="mt-n8 mb-n1">
          <v-row class="ml-n4 mr-0">
            <v-col cols="12">
              <div class="d-flex flex-row">
                <div class="d-flex flex-column">
                  <v-input prepend-icon="mdi-phone" :messages="projeto.tecnicoFinep.telefone">
                    Telefone
                  </v-input>
                  <v-input prepend-icon="mdi-mail" :messages="projeto.tecnicoFinep.email" class="my-1">
                    E-mail
                  </v-input>
                </div>
              </div>
              <div class="d-flex flex-column">
                <v-select
                  v-model="projeto['analista_tecnico_finep_id']"
                  :items="opts.responsaveisFinep"
                  item-text="nome"
                  item-value="id"
                  label="Responsável técnico"
                  :disabled="isCancelado || isFinalizado || isAtivo"
                  @change="responsavelTecnicoChanged($event)">
                </v-select>
              </div>
            </v-col>
          </v-row>
        </v-card-text> -->

      </v-card>
    </v-col>

    <GModal :opened="isVisibleModalProjectWarning" title="Deseja continuar ?">
      <slot>
        <p> Tem certeza que deseja concluir o projeto? </p>
        <p> Essa ação <b>bloqueará</b> a edição do projeto e deixará o projeto habilitado apenas para consultas</p>
      </slot>
      <template v-slot:buttons="{}">
        <v-spacer></v-spacer>
        <v-btn class="px-5" color="secondary" depressed @click="closeModal()">
          Cancelar
        </v-btn>
        <v-btn class="px-5" color="primary" depressed @click="doConcludeProject()">Confirmar
        </v-btn>
        <v-spacer></v-spacer>
      </template>
    </GModal>

    <FormModal
      :opts="opts"
      title="Cadastrar responsável FINEP"
      :cols="colsResponsavelFinep"
      :opened.sync="isVisibleAddFinep"
      :value.sync="novoResponsavel"
      @save="saveResponsavelFinep"
    >
    </FormModal>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import inputV from '@/components/input-v.vue';
import * as moment from 'moment';

const StatusProjetoEnum = {
  ATIVO: 1,
  CANCELADO: 2,
  FINALIZADO: 3,
  EM_DEFINICAO_PROJETO: 4,
};

export default {
  // props: {},
  components: {
    inputV,
    DescricaoDispendios: () => import('../descricao-dispendios/descricao-dispendios.vue'),
    ParametrizacaoOrcamento: () => import('../orcamentos/parametrizacao-orcamento.vue'),
    OrcamentoDetalhado: () => import('../orcamentos/orcamento-detalhado.vue'),
    GModal: () => import('@/components/g-modal.vue'),
    FormModal: () => import('@/components/form-modal.vue'),
  },
  // mixins: [],
  // directives: {},
  data() {
    return {
      projeto: {
        id: null,
        nro_contrato: '',
        titulo: '',
        data_inicio: '',
        data_fim: '',
        status: 4,
        responsavel_tecnico: '',
        responsavel_financeiro: '',
        data_protocolo: null,
        data_assinatura: null,
        periodo_retroativo: null,
        percentual_comprovacao: null,
        referencia_contrato: '',
        analista_financeiro_galapos_id: null,
        gestor_galapos_id: null,
        consultor_galapos_id: null,
        analista_financeiro_finep_id: 0,
        analista_tecnico_finep_id: 0,
        financeiroFinep: {},
        tecnicoFinep: {},
        meses: 0,
      },
      opts: {
        status: [
          {
            id: 1,
            name: 'Ativo',
            disabled: this.isCancelado || this.isFinalizado,
          },
          {
            id: 2,
            name: 'Cancelado',
            disabled: this.isAtivo,
          },
          {
            id: 3,
            name: 'Finalizado',
            disabled: this.isAtivo,
          },
          {
            id: 4,
            name: 'Em definição do projeto',
          },
        ],
        responsaveisFinep: [],
        consultoresGalapos: [],
      },
      currentView: 0,
      currentStep: 0,
      StatusProjetoEnum: {
        ATIVO: 1,
        CANCELADO: 2,
        FINALIZADO: 3,
        EM_DEFINICAO_PROJETO: 4,
      },
      isVisibleModalProjectWarning: false,
      isVisibleAddFinep: false,
      novoResponsavel: {},
      isValidParcelas: false,
    }
  },
  computed: {
    ...mapGetters(['clientId']),
    isEdicaoProjeto() {
      return (this.$route.params.id || this.projeto.id) ? true : false;
    },
    projetoId() {
      return this.$route.params.id || this.projeto.id;
    },
    canEdit() {
      return true;
    },
    isCancelado() {
      return this.projeto.status == this.StatusProjetoEnum.CANCELADO
    },
    isFinalizado() {
      return this.projeto.status == this.StatusProjetoEnum.FINALIZADO
    },
    isAtivo() {
      return this.projeto.status == this.StatusProjetoEnum.ATIVO
    },
    cols() {
      return [
        {
          key: 'referencia_contrato',
          name: 'Referência do contrato',
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: 'required' }],
          editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
          colSize: 6,
        },
        {
          key: 'nro_contrato',
          name: 'Nº do contrato',
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: 'required' }],
          editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
          colSize: 6,
        },
        {
          key: 'titulo',
          name: 'Nome do projeto',
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: 'required' }],
          editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
          colSize: 6,
        },
        {
          key: 'status',
          name: 'Status',
          type: this.$fieldTypes.SELECT,
          rel: { to: 'status', key: 'id', name: 'name' },
          rules: [{ rule: 'required' }],
          editable: this.projeto.status < StatusProjetoEnum.EM_DEFINICAO_PROJETO,
          colSize: 6,
        },
        {
          key: 'data_protocolo',
          name: 'Protocolo do projeto',
          type: this.$fieldTypes.DATE,
          rules: [{ rule: 'required' }],
          editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
          colSize: 6,
        },
        {
          key: 'periodo_retroativo',
          name: 'Período retroativo',
          type: this.$fieldTypes.DATE,
          rules: [{ rule: 'required' }],
          editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
          colSize: 6,
        },
        {
          key: 'data_assinatura',
          name: 'Assinatura do contrato',
          type: this.$fieldTypes.DATE,
          rules: [{ rule: 'required' }],
          editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
          colSize: 6,
        },
        {
          key: 'meses',
          name: 'Nº de meses do contrato',
          type: this.$fieldTypes.NUMBER,
          rules: [{ rule: 'required' }],
          editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
          colSize: 6,
        },
        {
          key: '',
          name: 'Galapos',
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: 'analista_financeiro_galapos_id',
          name: 'Analista financeiro',
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'consultoresGalapos', key: 'id', name: 'nome' },
          editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
          colSize: 12,
        },
        {
          key: 'gestor_galapos_id',
          name: 'Gestor',
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'consultoresGalapos', key: 'id', name: 'nome' },
          editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
          colSize: 12,
        },
        {
          key: 'consultor_galapos_id',
          name: 'Consultor',
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'consultoresGalapos', key: 'id', name: 'nome' },
          editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
          colSize: 12,
        },
      ]
    },
    colsResponsavelFinep() {
      return [
        {
          key: 'nome',
          name: 'Nome',
          type: this.$fieldTypes.TEXT,
          colSize: 12,
        },
        {
          key: 'telefone',
          name: 'Telefone',
          type: this.$fieldTypes.TELEPHONE,
          colSize: 6,
        },
        {
          key: 'email',
          name: 'Email',
          type: this.$fieldTypes.TEXT,
          colSize: 6,
        },
      ]
    },
    resourceUrl() {
      return this.apiResource(`/v1/captacao/projetos/${this.clientId}`.concat(this.projetoId ? '/' + this.projetoId : ''));
    },
    resourceResponsaveisFinep() {
      return this.apiResource(`/v1/captacao/responsaveis-finep`)
    },
    resourceUrlConclude() {
      return this.apiResource(`/v1/captacao/projetos/${this.clientId}/projeto/${this.projetoId}/concluir`);
    }
  },
  // filters: {},
  created() {
    this.getResponsaveisFinep();
    this.alteraTituloFormulario();
    this.getConsultores();
    if (this.isEdicaoProjeto) {
      this.doLoadEdicao();
    }
  },
  // mounted() {},
  // updated() {},
  // destroyed() {},
  methods: {
    alteraTituloFormulario() {
      return this.isEdicaoProjeto ? this.$route.meta.pageTitle = 'Edição projeto captação' : this.$route.meta.pageTitle = 'Cadastro projeto captação';
    },
    doExitSave() {
      this.$router.go(-1);
    },
    doFinalizeProject() {
      const resource = this.resourceUrl;
      return resource.save({ ...this.projeto }).then((response) => {
        this.doExitSave()
        return response;
      });
    },
    doLoadEdicao() {
      this.resourceUrl.get().then((response) => {
        this.projeto = response;
      })
    },
    setCurrentStepAndStatus(step) {
      this.currentStep = step;
    },
    chooseStatus() {
      return StatusProjetoEnum.EM_DEFINICAO_PROJETO;
    },
    changedField(field, $event) {
      if (field == 'data_protocolo') {
        const newDate = moment($event).subtract(180, 'days').format('YYYY-MM-DD')
        this.projeto.periodo_retroativo = newDate;
      }
    },
    getConsultores() {
      const resource = this.apiResource(`/v1/consultores`);
      resource.get({ query: `empresaId=${this.clientId}` }).then((result) => {
        return this.opts.consultoresGalapos = result.consultores;
      })
    },
    async getResponsaveisFinep() {
      const resource = this.apiResource(`/v1/captacao/responsaveis-finep/selecao`);
      await resource.get().then((result) => {
        return this.opts.responsaveisFinep = result;
      });
    },
    async saveResponsavelFinep(formData = {}) {
      const resource = this.resourceResponsaveisFinep
      return await resource.save({...formData}).then(() => {
        this.$notify({
          group: "geral",
          duration: 5000,
          type: "success",
          title: "Responsável FINEP",
          text: "Novo responsável cadastrado com sucesso !",
        })
        this.isVisibleAddFinep = false;
        this.getResponsaveisFinep();
      })
    },
    responsavelFinanceiroChanged(responsavelId) {
      if (responsavelId == null) return;
      const responsavel = this.opts.responsaveisFinep.filter((resp) => resp.id == responsavelId)[0];
      this.responsavelFinanceiroFinep = responsavel != null ? responsavel : this.responsavelFinanceiroFinep;
      this.projeto.financeiroFinep = responsavel != null ? responsavel : this.responsavelFinanceiroFinep;
      this.projeto.analista_financeiro_finep_id = responsavel?.id;
    },
    responsavelTecnicoChanged(responsavelId) {
      if (responsavelId == null) return;
      const responsavel = this.opts.responsaveisFinep.filter((resp) => resp.id == responsavelId)[0];
      this.responsavelTecnicoFinep = responsavel != null ? responsavel : this.responsavelTecnicoFinep;
      this.projeto.tecnicoFinep = responsavel != null ? responsavel : this.projeto.tecnicoFinep;
      this.projeto.analista_tecnico_finep_id = responsavel?.id;
    },
    doSave() {
      const resource = this.resourceUrl;
      const that = this;
      this.projeto.status = this.projeto.status == this.StatusProjetoEnum.ATIVO ? 1 : this.chooseStatus();
      return resource.save({ ...this.projeto }).then((response) => {
        that.validaStepAndSave(response.id);
        that.currentStep = that.currentStep + 1;
        that.projeto.id = response.id;
        this.$route.params['id'] = response.id;
        return response;
      });
    },
    doConcludeProject() {
      const resource = this.resourceUrlConclude;
      const that = this;
      if(this.validaPreenchimentoParcelas()) {
        this.projeto.status = 1;
        return resource.save({ ...this.projeto }).then((response) => {
          that.validaStepAndSave(response.id);
          that.currentStep = that.currentStep + 1;
          that.projeto.id = response.id;
          this.$route.params['id'] = response.id;
          this.closeModal();
          return response;
        }, (error) => {
          this.projeto.status = 4;
          console.error(error);
        });
      }
    },
    validaStepAndSave(projetoId) {
      if (this.currentStep == 0 && !this.projeto.id) {
        this.$router.push({ name: "projetos-captacao-edicao", params: { id: projetoId } });
      }
    },
    closeModal() {
      this.isVisibleModalProjectWarning = false
    },
    validaParcelas(parcelas) {
      parcelas.map((p) => {
        if(!p.data_inicio || !p.data_fim) {
          return this.isValidParcelas = false;
        }
        return this.isValidParcelas = true;
      })
    },
    validaPreenchimentoParcelas() {
      if(this.isValidParcelas) return true;
      this.$notify({
        group: "geral",
        duration: 5000,
        type: "error",
        title: "Parcelas",
        text: "A ativação do projeto depende do preenchimento correto das datas das parcelas."
      })
      this.isVisibleModalProjectWarning = false;
      return false;
    }
  },
  watch: {
    clientId() {
      this.$router.push({ name: 'projetos-captacao' });
    }
  },
}
</script>

<style></style>